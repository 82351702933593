import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Layout from "../layouts/en"
import decentralized from "../images/decentralized.png"
import decentralizedDark from "../images/decentralized-dark.png"
import SPHeader from "../components/static-pages-layout/static-page-header"
import SPP from "../components/static-pages-layout/static-page-p"
import SPSection from "../components/static-pages-layout/static-page-section"

const AboutDevlandPage = ({ location }) => {
  const langPaths = { es: "/es/acerca-de-devland" }
  return (
    <Layout location={location} langPaths={langPaths}>
      <Helmet>
        <title>About DevLand</title>
        <meta
          name="description"
          content="DevLand is a software engineering DAO"
        />
      </Helmet>
      <SPHeader
        superTitle="About"
        title="DevLand"
        imageLight={decentralized}
        imageDark={decentralizedDark}
      >
        DevLand is a software engineering DAO that uses blockchain and web
        technologies to provide its members a set social and professional
        networking services, quality and curated technical content, technical
        training and decentralized development services.
      </SPHeader>
      <SPSection title="Our mission">
        <SPP>
          The mission behind DevLand is to decentralize the corporate fabric of
          the IT sector step by step, shaping it in a horizontal way in which
          the power between the actors that compose it is rebalanced.
        </SPP>
        <SPP>
          We want to democratize the software development world, from learning
          to code execution, going through software design and implementation
          too.
        </SPP>
      </SPSection>
      <SPSection title="Our infrastructure">
        <SPP>We’ve chosen to start with Stellar as our blockchain.</SPP>
        <SPP>
          In the earliest stages of the project we’ll use a semi-centralized
          approach and Stellar is the ideal tool for this kind of scenario as we
          don't need turing complete smart contracts in a semi-centralized
          environment, but we can leverage its ease of integration, quick
          performance and low transaction costs.
        </SPP>
      </SPSection>
    </Layout>
  )
}

export default AboutDevlandPage
export const pageQuery = graphql`
  query aboutDevlandQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
